<template>
  <v-container fluid class="mt-4 ml-2 pr-6">
    <!--  -->
    <v-row v-if="( getdatosUsuario.idpuesto == 19 && [1258].includes( getdatosUsuario.iderp ) ) || getdatosUsuario.idpuesto != 19">
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title class="text-subtitle-1">
            Mis requisiciones
            <v-spacer></v-spacer>

            <v-btn color="primary" @click="initialize()" small tile>
              <v-icon small left>mdi-refresh</v-icon>
              Consultar
            </v-btn>

            <v-btn
              color="orange"
              @click="dialogNuevaNomina.estatus = true"
              small
              tile
              class="ml-2"
              dark
              v-if="liberar"
            >
              <v-icon left small>mdi-plus</v-icon>
              Agregar
            </v-btn>
          </v-card-title>
          <v-card-text>

            <v-row v-if="!liberar">
              <v-col cols="12">
                <v-alert type="error">
                  Para liberar el botón de agregar requisiciones, sube tus comprobantes primero
                </v-alert>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3" lg="2" xl="2">
                <v-text-field
                  filled
                  single-line
                  label="Fecha inicio"
                  type="date"
                  hide-details
                  v-model="fecha_inicio"
                  dense
                  @keyup.enter="initialize()"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3" lg="2" xl="2">
                <v-text-field
                  filled
                  single-line
                  label="Fecha final"
                  type="date"
                  hide-details
                  v-model="fecha_final"
                  dense
                  @keyup.enter="initialize()"
                ></v-text-field>
              </v-col>

              <v-spacer></v-spacer>

              <v-col cols="12" md="6">
                <v-text-field
                  filled
                  single-line
                  label="Búscar"
                  append-icon="mdi-magnify"
                  hide-details
                  v-model="buscar"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- REQUISICIONES -->
            <v-data-table
              :headers="headers"
              :items="nomina"
              dense
              :search="buscar"
              class="elevation-0 mt-4"
            >
              <!-- Monto con formato -->
              <template v-slot:item.total="{ item }">
                <span>{{
                  new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(item.total)
                }}</span>
              </template>

              <template v-slot:item.id_requisicion_compra_estatus="{ item }">
                <v-chip
                  color="grey"
                  small
                  dark
                  v-if="item.id_requisicion_compra_estatus == 1"
                  >Nueva</v-chip
                >
                <v-chip
                  color="orange"
                  small
                  dark
                  v-if="item.id_requisicion_compra_estatus == 5"
                  >Pre Autorizada</v-chip
                >
                <v-chip
                  color="blue"
                  small
                  dark
                  v-if="item.id_requisicion_compra_estatus == 2"
                  >Pendiente</v-chip
                >
                <v-chip
                  color="green"
                  small
                  dark
                  v-if="item.id_requisicion_compra_estatus == 3"
                  >Autorizada</v-chip
                >
                <v-chip
                  color="black"
                  small
                  dark
                  v-if="item.id_requisicion_compra_estatus == 4"
                  >Rechazada</v-chip
                >
                <v-chip
                  color="green"
                  small
                  dark
                  v-if="item.id_requisicion_compra_estatus == 6"
                  >Aceptada</v-chip
                >
                <v-chip
                  color="green"
                  small
                  dark
                  v-if="item.id_requisicion_compra_estatus == 7"
                  >Procesada</v-chip
                >
              </template>

              <template v-slot:item.editar="{ item }">
                  <!-- Eliminar -->
                  <v-tooltip bottom v-if="item.id_requisicion_compra_estatus == 1" color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-if="item.id_requisicion_compra_estatus == 1"
                        :disabled="editedItem.descripcion == 'Procesada' || editedItem.descripcion == 'Pre Autorizada'"
                        small
                        color="primary"
                        @click="editItem3(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-book-edit
                      </v-icon>
                    </template>
                    <span>¿Editar?</span>
                  </v-tooltip>
               </template>

              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" color="blue" @click="editItem(item)">
                  mdi-eye
                </v-icon>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialgo para editar o ver la requisición -->
    <v-dialog v-model="dialog" max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="text-h6">Folio: {{ editedItem.id }}</span>
          <v-spacer></v-spacer>
          <v-btn color="red" dark rounded small icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="black--text" v-if="editedIndex >= 0">
          <span><b>Solicitante: </b> {{ editedItem.nombre_completo }}</span>
          <br />
          <b>Estatus: {{ editedItem.estatus }}</b>
          <span>
            <v-chip
              color="grey"
              small
              dark
              v-if="editedItem.descripcion == 'Nueva'"
              >Nueva</v-chip
            >
            <v-chip
              color="orange"
              small
              dark
              v-if="editedItem.descripcion == 'Pre Autorizada'"
              >Pre Autorizada</v-chip
            >
            <v-chip
              color="blue"
              small
              dark
              v-if="editedItem.descripcion == 'Pendiente'"
              >Pendiente</v-chip
            >
            <v-chip
              color="green"
              small
              dark
              v-if="editedItem.descripcion == 'Autorizada'"
              >Autorizada</v-chip
            >
            <v-chip
              color="black"
              small
              dark
              v-if="editedItem.descripcion == 'Rechazada'"
              >Rechazada</v-chip
            >
            <v-chip
              color="green"
              small
              dark
              v-if="editedItem.descripcion == 'Aceptada'"
              >Aceptada</v-chip
            >
            <v-chip
              color="green"
              small
              dark
              v-if="editedItem.descripcion == 'Procesada'"
              >Procesada</v-chip
            >
          </span>
          <br />
          <br />

          <v-row>
            <v-col
              cols="12"
              md="4"
              lg="3"
              v-for="(img, i) in editedItem.fotos"
              :key="i"
            >
              <v-card
                class="py-4 shadowCard"
                v-if="img.extension != 'pdf'"
                @click="verImagen2(img)"
              >
                <v-img
                  :src="url_servidor + img.name"
                  contain
                  aspect-ratio="2"
                ></v-img>
              </v-card>
              <v-card
                class="py-4 shadowCard"
                v-if="img.extension == 'pdf'"
                @click="verImagen2(img)"
              >
                <embed :src="url_servidor + img.name" type="application/pdf" />
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <!-- DESCAR EL EXCEL -->
            <v-col cols="12">
              <v-btn class="mr-2" small dark color="green" @click="exportar()">
                <v-icon>mdi-microsoft-excel</v-icon>
              </v-btn>

              <v-btn
                small
                color="orange"
                dark
                @click="dialogPartida = true"
                v-if="['Nueva'].includes(editedItem.descripcion)"
                class="mr-2"
              >
                Agregar Partida
              </v-btn>

              <v-btn
                small
                color="orange"
                dark
                @click="dialogSubirCotizacion = true"
                v-if="['Nueva'].includes(editedItem.descripcion)"
                class="mr-2"
              >
                Subir cotización
              </v-btn>

              <!-- <v-btn 
                small 
                color="orange"
                dark
                @click="abrirDialgoCargaComprobantes( 1 )"
                v-if="['Nueva','Rechazada'].includes(editedItem.descripcion)"
                class="mr-2"
              >
                Subir cotización
              </v-btn>   Angel-->

              <!-- <v-btn 
                small 
                color="brown"
                dark
                v-if="['Pre Autorizada'].includes(editedItem.descripcion)"
                @click="abrirDialgoCargaComprobantes( 2 )"
              >
                Subir Comprobante
              </v-btn>

              <v-btn 
                small 
                color="brown"
                dark      
                v-if="['Pre Autorizada'].includes(editedItem.descripcion)"        
                @click="dialogTipoPago = true"
              >
                Metodo de Pago
              </v-btn> -->
            </v-col>

            <!-- Tabla de requisiciones -->
            <v-col cols="12">
              <v-data-table
                :headers="headersDetalle"
                :items="editedItem.detalle"
                class="elevation-0"
                dense
                v-model="requisicionesSelected"
                show-select
              >
                <!-- Monto con formato -->
                <template v-slot:item.costo_total="{ item }">
                  <span>{{
                    new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(item.costo_total)
                  }}</span>
                </template>

                <template v-slot:item.cotizacion_sn="{ item }">
                  <span v-for="(foto, i) in item.fotos" :key="i">
                    <v-icon
                      color="primary"
                      @click="verImagen(foto.name)"
                      v-if="extensionImagenes.includes(foto.extension)"
                    >
                      mdi-file-image
                    </v-icon>
                  </span>
                </template>

                <template v-slot:item.imagen_pago="{ item }">
                  <span v-for="(foto, i) in item.fotosPago" :key="i">
                    <v-icon
                      color="primary"
                      @click="verImagen(foto.name)"
                      v-if="extensionImagenes.includes(foto.extension)"
                    >
                      mdi-file-image
                    </v-icon>
                  </span>
                </template>

                <template v-slot:item.estatus="{ item }">
                  <v-chip color="grey" small dark v-if="item.estatus == 0"
                    >Nueva</v-chip
                  >
                  <v-chip color="black" small dark v-if="item.estatus == 4"
                    >Rechazada</v-chip
                  >
                </template>


                <template v-slot:item.editar="{ item }">
                  <!-- Eliminar -->
                  <v-tooltip bottom v-if="item.activo_sn == 1" color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-if="item.activo_sn == 1"
                        :disabled="editedItem.descripcion == 'Procesada' || editedItem.descripcion == 'Pre Autorizada'"
                        small
                        color="primary"
                        @click="editItem2(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-book-edit
                      </v-icon>
                    </template>
                    <span>¿Editar?</span>
                  </v-tooltip>
                </template>


                <!-- Acciones -->
                <template v-slot:item.actions="{ item }">
                  <span v-if="item.activo_sn == 0">
                    <v-chip small color="red" dark>Eliminada</v-chip>
                  </span>
                  <!-- Eliminar -->
                  <v-tooltip bottom v-if="item.activo_sn == 1" color="red">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-if="item.activo_sn == 1"
                        :disabled="editedItem.descripcion == 'Procesada' || editedItem.descripcion == 'Pre Autorizada'"
                        small
                        color="red"
                        @click="eliminarRequisicion(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-close
                      </v-icon>
                    </template>
                    <span>¿Eliminar?</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <!-- <v-btn 
            small 
            color="primary"
            dark
            v-if="['Pre Autorizada'].includes(editedItem.descripcion)"
            @click="updateEstatus( 2 )"
          >
            Solicitar Revisión
          </v-btn> -->
          <v-spacer></v-spacer>
          <span class="text-h6">
            TOTAL:
            <!-- Monto con formato -->
            <span>{{
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(editedItem.total)
            }}</span>
          </span>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- SUBIR LOS ARCHIVOS -->
    <v-dialog v-model="dialogSubirComprobante" max-width="400px">
      <v-card>
        <v-card-title>
          <span class="text-h6">
            Folios:
            <span v-for="(requi, i) in requisicionesSelected" :key="i"
              >{{ requi.id }},
            </span>
          </span>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            dark
            rounded
            small
            icon
            @click="
              (dialogSubirComprobante = false),
                (file = null),
                (vistaPrevia = null)
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="black--text" align="center">
          <div v-if="!file">
            <div
              :class="['dropZone', dragging ? 'dropZone-over' : '']"
              @dragenter="dragging = true"
              @dragleave="dragging = false"
            >
              <div class="dropZone-info" @drag="onChange">
                <span class="fa fa-cloud-upload dropZone-title"></span>
                <span class="dropZone-title blue--text"
                  ><b>Arrastre el archivo para cargar</b></span
                >
                <div class="dropZone-upload-limit-info">
                  <div class="black--text">
                    Extensión: png, jpg, jpeg, svg, xls, xlsx
                  </div>
                  <div class="black--text">Tamaño máximo: 10 MB</div>
                </div>
              </div>
              <input type="file" @change="onChange" />
            </div>
          </div>

          <v-btn
            v-else
            color="red"
            class="mb-2"
            dense
            @click="removeFile"
            fab
            small
            dark
            ><v-icon>mdi-delete</v-icon></v-btn
          >
          <v-img
            :src="vistaPrevia.url"
            v-if="vistaPrevia && file.type.match('image.*')"
            contain
            max-height="300"
          />
          <v-btn
            color="orange"
            dark
            small
            block
            v-if="vistaPrevia && !file.type.match('image.*')"
            >Sin vista previa</v-btn
          >
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" block small v-if="file" @click="grabarDatos()"
            >Grabar datos</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Visualizar imagenes -->
    <v-dialog v-model="dialogImagen" max-width="600px">
      <v-card class="elevation-0">
        <v-card-text>
          <!-- Imagen -->
          <v-col cols="12">
            <v-img
              :src="url_servidor + imagen"
              contain
              max-width="600"
              height="750"
            >
            </v-img>
          </v-col>
        </v-card-text>
        <!-- Guardar la información  -->
        <v-btn
          absolute
          right
          top
          color="grey"
          dark
          fab
          class="elevation-6 mt-8"
          @click="dialogImagen = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogTipoPago" max-width="350px">
      <v-card>
        <v-card-title>
          Metodo de Pago
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            dark
            rounded
            small
            icon
            @click="dialogTipoPago = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="black--text">
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                label="Tipo de pago"
                filled
                dense
                clearable
                v-model="tipopago"
                :items="[
                  { id_tipopago: 0, tipopago: 'Sin Método de pago' },
                  { id_tipopago: 1, tipopago: 'Tarjeta' },
                  { id_tipopago: 2, tipopago: 'Transferencia/Deposito' },
                  { id_tipopago: 3, tipopago: 'Efectivo' },
                ]"
                persistent-hint
                item-text="tipopago"
                item-value="id_tipopago"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn dark block @click="tipospago()" color="primary">Agregar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogSubirCotizacion" max-width="700px">
      <v-card>
        <v-card-title>
          <span class="text-h6">Folio: {{ editedItem.id }} </span>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            dark
            rounded
            small
            icon
            @click="dialogSubirCotizacion = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-file-input
                v-model="files"
                accept="image/png, image/jpeg, image/bmp, application/pdf"
                placeholder="Selecciona tus imagenes"
                label="Subir Cotizaciones"
                multiple
                prepend-icon="mdi-paperclip"
                @change="cargarFotos()"
              >
                <template v-slot:selection="{ index, text }">
                  <v-chip
                    small
                    label
                    color="primary"
                    close
                    @click:close="eliminarfile(index, text)"
                  >
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                  v-for="(img, i) in vistaPrevias"
                  :key="i"
                >
                  <v-card
                    class="py-4 shadowCard"
                    v-if="img.extensioArchivo != 'pdf'"
                  >
                    <v-img :src="img.url" contain aspect-ratio="2"></v-img>
                    <v-btn
                      color="error"
                      small
                      @click="eliminarFoto(img.url)"
                      top
                      right
                      absolute
                      fab
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-card>
                  <v-card
                    class="py-4 shadowCard"
                    v-if="img.extensioArchivo == 'pdf'"
                  >
                    <embed :src="img.url" type="application/pdf" />
                    <v-btn
                      color="error"
                      small
                      @click="eliminarFoto(img.url)"
                      top
                      right
                      absolute
                      fab
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-btn
              v-bind:disabled="!files.length"
              color="primary"
              @click="
                subirCotizacion(),
                  (verTabla = false),
                  (dialogSubirCotizacion = false)
              "
            >
              Guardar</v-btn
            ><br /><br />
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogPartida" max-width="350px">
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            dark
            rounded
            small
            icon
            @click="dialogPartida = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="black--text">
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                label="Elemento de requisición"
                filled
                dense
                clearable
                v-model="editedItems.id_elemento_requisicion"
                :items="elementos"
                persistent-hint
                item-text="elemento_mantenimiento"
                item-value="id_elemento_mantenimiento"
              >
              </v-autocomplete>

              <!-- Plantel estado de resultados -->
              <v-autocomplete
                filled
                dense
                clearable
                v-model="editedItems.id_plantel_resultados"
                :items="planteles"
                label="Plantel stdo. resultados"
                persistent-hint
                item-text="plantel"
                item-value="id_plantel"
              >
              </v-autocomplete>

              <v-autocomplete
                filled
                dense
                clearable
                v-model="editedItems.id_plantel"
                :items="planteles"
                label="Selecciona plantel"
                persistent-hint
                item-text="plantel"
                item-value="id_plantel"
              >
              </v-autocomplete>

              <v-text-field
                filled
                label="Concepto"
                dense
                v-model="editedItems.concepto"
              ></v-text-field>

              <v-text-field
                filled
                label="Cantidad"
                dense
                type="number"
                v-model="editedItems.cantidad"
              ></v-text-field>

              <v-text-field
                filled
                label="Costo Unit."
                dense
                type="number"
                v-model="editedItems.costo_unitario"
              ></v-text-field>

              <v-text-field
                filled
                label="Costo total"
                dense
                readonly
                v-model="editedItems.costo_total"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn dark block @click="agregar()" color="primary">Agregar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEditarPartida" max-width="350px">
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            dark
            rounded
            small
            icon
            @click="dialogEditarPartida = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="black--text">
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                label="Elemento de requisición"
                filled
                dense
                clearable
                v-model="actualizarItems.id_elemento_requisicion"
                :items="elementos"
                persistent-hint
                item-text="elemento_mantenimiento"
                item-value="id_elemento_mantenimiento"
              >
              </v-autocomplete>

              <!-- Plantel estado de resultados -->
              <v-autocomplete
                filled
                dense
                clearable
                v-model="actualizarItems.id_plantel_resultados"
                :items="planteles"
                label="Plantel stdo. resultados"
                persistent-hint
                item-text="plantel"
                item-value="id_plantel"
              >
              </v-autocomplete>

              <v-autocomplete
                filled
                dense
                clearable
                v-model="actualizarItems.id_plantel"
                :items="planteles"
                label="Selecciona plantel"
                persistent-hint
                item-text="plantel"
                item-value="id_plantel"
              >
              </v-autocomplete>

              <v-text-field
                filled
                label="Concepto"
                dense
                v-model="actualizarItems.concepto"
              ></v-text-field>

              <v-text-field
                filled
                label="Cantidad"
                dense
                type="number"
                v-model="actualizarItems.cantidad"
              ></v-text-field>

              <v-text-field
                filled
                label="Costo Unit."
                dense
                type="number"
                v-model="actualizarItems.costo_unitario"
              ></v-text-field>

              <v-text-field
                filled
                label="Costo total"
                dense
                readonly
                v-model="calcularCostoTotal"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn dark block @click="editarPartida()" color="primary">Editar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEditarRequi" max-width="350px">
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            dark
            rounded
            small
            icon
            @click="dialogEditarRequi = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="black--text">
          <v-row>
            <v-col cols="12">
             
              <v-autocomplete
                filled
                dense
                clearable
                v-model="actualizarItemsRequi.id_ciclo"
                :items="ciclos"
                label="Selecciona un ciclo"
                persistent-hint
                item-text="ciclo"
                item-value="id_ciclo"
              >
              </v-autocomplete>

              <v-text-field
                filled
                label="Motivo/Comentarios"
                dense
                v-model="actualizarItemsRequi.comentarios"
              ></v-text-field>

            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn dark block @click="editarRequi()" color="primary">Editar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogVer"
      max-width="600px"
      v-if="img"
      persistent
      :fullscreen="fullscreen"
    >
      <v-card class="elevation-0">
        <v-card-text>
          <!-- Imagen -->
          <v-col cols="12">
            <v-card
              height="100%"
              class="elevation-0"
              align="center"
              outlined
              v-if="img.extension != 'pdf'"
            >
              <v-img
                :src="url_servidor + img.name"
                contain
                aspect-ratio="2"
                v-if="fullscreen"
              ></v-img>

              <v-img
                :src="url_servidor + img.name"
                contain
                aspect-ratio="2"
                max-width="800"
                v-else
              ></v-img>
            </v-card>

            <v-card v-else>
              <embed :src="url_servidor + img.name" type="application/pdf" />
            </v-card>
          </v-col>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
          <v-btn
            color="error"
            text
            class="mr-2"
            @click="fullscreen = true"
            v-if="!fullscreen"
            >Zoom</v-btn
          >
          <v-btn
            color="error"
            text
            class="mr-2"
            @click="fullscreen = false"
            v-else
            >Sin, Zoom</v-btn
          >
          <!-- Guardar la información  -->
          <v-btn
            color="primary"
            dark
            class="elevation-6"
            @click="dialogVer = false"
            >Okey</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta v-if="respuestaAlerta" :parametros="parametros" />
    <carga v-if="cargar" />

    <SolicitarRequi
      v-if="dialogNuevaNomina.estatus"
      :dialogNuevaNomina="dialogNuevaNomina"
    />
  </v-container>
</template>

<script>
import axios from 'axios';
import { mapGetters } from "vuex";
// Componentes
import Alerta from "@/components/alertas/Alerta.vue";
import carga from "@/components/alertas/carga.vue";

import SolicitarRequi from "@/components/egresos/SolicitarRequi.vue";

import validarErrores from "@/mixins/validarErrores";
import funcionesExcel from "@/mixins/funcionesExcel";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export default {
  components: {
    Alerta,
    carga,
    SolicitarRequi,
  },

  mixins: [validarErrores, funcionesExcel],

  data: () => ({
    // Alertas
    parametros: {
      dialogError: false,
      mensaje: "",
      color: "",
    },

    respuestaAlerta: false,
    loader: true,
    cargar: false,

    dialog: false,
    dialogDelete: false,
    dialogTipoPago: false,
    dialogPartida: false,
    dialogEditarPartida: false,
    dialogEditarRequi: false,
    dialogCrearElemento: false,

    headers: [
      { text: "ID", value: "id", sortable: false },
      { text: "Usuario", value: "nombre_completo" },
      { text: "Ciclo", value: "ciclo" },
      { text: "Motivo", value: "comentarios" },
      { text: "Proyecto", value: "proyecto" },
      { text: "Estatus", value: "id_requisicion_compra_estatus" },
      { text: "Total", value: "total" },
      { text: "Editar", value: "editar" },
      { text: "Actions", value: "actions", sortable: false },
    ],

    headersDetalle: [
      { text: "ID", value: "id", sortable: false },
      { text: "Elemento", value: "elemento_mantenimiento" },
      { text: "Concepto", value: "concepto" },
      { text: "Plantel", value: "plantel" },
      { text: "Proyecto", value: "proyecto" },
      { text: "Cantidad", value: "cantidad" },
      { text: "Costo Unit.", value: "costo_unitario" },
      { text: "Total", value: "costo_total" },
      { text: "Estatus", value: "estatus" },
      { text: "Editar", value: "editar" },
      { text: "Eliminar", value: "actions", sortable: false },
      // { text: 'Cotización'   , value: 'cotizacion_sn'  },
      // { text: 'Pago'         , value: 'imagen_pago'    },
      // { text: 'Actions'      , value: 'actions', sortable: false },
    ],

    requisicionesSelected: [],
    vistaPrevias: [],

    planteles: [],
    elementos: [],
    requisiciones: [],
    detalles: [],
    ciclos: [],

    nomina: [],
    opVisualizacion: 1,

    editedIndex: -1,

    editedItem: {
      id: "",
      nombre_completo: "",
      detalles: [],
    },

    editedItems: {
      id: 0,
      id_elemento_requisicion: 0,
      id_plantel: 0,
      concepto: "Pago de",
      cantidad: 1,
      costo_unitario: 0,
      costo_total: 0,
      id_empleado: 0,
      detalles: [],
      id_plantel_resultados:0,
    },

    actualizarItems: {
      id: 0,
      id_elemento_requisicion: 0,
      id_plantel: 0,
      concepto: "Pago de",
      cantidad: 0,
      costo_unitario: 0,
      costo_total: 0,
      id_empleado: 0,
      detalles: [],
      id_plantel_resultados:0,
    },

    actualizarItemsRequi: {
      id_ciclo: 0,
      comentarios: "",
    },

    defaultItem: {
      idsolicitud_nomina: "",
      id_usuario: 0,
      idareas_ticket: 0,
    },

    file: "",
    dragging: false,
    vistaPrevia: null,
    autorizar: null,
    nota_rechazo: "",
    url: "",
    img: null,
    fullscreen: false,
    dialogVer: false,

    fecha_inicio: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    fecha_final: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    buscar: "",

    dialogNuevaNomina: {
      estatus: false,
    },

    dialogSubirComprobante: false,
    archivos: null,
    dialogImagen: false,
    dialogSubirCotizacion: false,
    url_servidor: "",
    imagen: "",
    extensionImagenes: ["BMP", "GIF", "jpg", "jpeg", "png", "webp"],
    total: 0,
    tipo_pago: 0,
    files: [],
    tipopago: null,
    costoFinal: 0,

    elementorequisicion: null,

    liberar: false
  }),

  computed: {
    ...mapGetters("login", ["getdatosUsuario"]),
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },

    calcularCostoTotal() {
      
      this.costoFinal = this.actualizarItems.costo_unitario * this.actualizarItems.cantidad;
      
      return this.costoFinal       
      
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },

    "dialogNuevaNomina.estatus"(val) {
      if (!val) {
        this.initialize();
      }
    },

    "editedItems.cantidad"(value) {
      if (value && this.editedItems.costo_unitario) {
        this.editedItems.costo_total = value * this.editedItems.costo_unitario;
      }
    },

    "editedItems.costo_unitario"(value) {
      if (this.editedItems.cantidad && value) {
        this.editedItems.costo_total = value * this.editedItems.cantidad;
      }
    },

    "editedItems.id_elemento_requisicion"(value) {
      if (this.editedItems.id_elemento_requisicion) {
        this.editedItems.concepto =
          "Pago de: " +
          this.elementos.find(
            (el) =>
              el.id_elemento_mantenimiento ==
              this.editedItems.id_elemento_requisicion
          ).elemento_mantenimiento;
      }
    },

     "actualizarItems.id_elemento_requisicion"(value) {
      if (this.actualizarItems.id_elemento_requisicion) {
        this.actualizarItems.concepto =
          "Pago de: " +
          this.elementos.find(
            (el) =>
              el.id_elemento_mantenimiento ==
              this.actualizarItems.id_elemento_requisicion
          ).elemento_mantenimiento;
      }
    },

    "actualizarItems.id_elemento_requisicion"(value){
      if( value ){
        let existeElemento = this.elementos.find( el => el.id_elemento_mantenimiento == value)
        this.actualizarItems.id_plantel_resultados = existeElemento ? existeElemento.id_plantel : 0
      }else{
        this.actualizarItems.id_plantel_resultados = 0
      }
    },

    "editedItems.id_elemento_requisicion"(value){
      if( value ){
        let existeElemento = this.elementos.find( el => el.id_elemento_mantenimiento == value)
        this.editedItems.id_plantel_resultados = existeElemento ? existeElemento.id_plantel : 0
      }else{
        this.editedItems.id_plantel_resultados = 0
      }
    }
  },

  async created() {
    this.url = axios.defaults.baseURL + "justificantes/";
    this.url_servidor = axios.defaults.baseURL + "fotos-egresos/";

    await this.getPlanteles();
    await this.getElementosCompras();

    await this.getCiclos();

    this.initialize();
  },

  methods: {
    initialize() {
      this.nomina = [];
      this.cargar = true;
      const payload = {
        fecha_inicio: this.fecha_inicio,
        fecha_final: this.fecha_final,
        id_usuario: this.getdatosUsuario.iderp,
      };
      return this.$http
        .post("consultar.mis.requisiciones", payload)
        .then((response) => {
          this.nomina = response.data;

          this.misRquisicionesSinComprobante();

          this.cargar = false;

          // Validar si ya hay información, para poder subir el comprobante o mostrarlos
          if (this.editedIndex > -1) {
            const nomina = this.nomina.find(
              (el) => el.id == this.editedItem.id
            );

            this.editedItem = nomina;
          }
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    misRquisicionesSinComprobante( ){
      this.liberar = false
      this.nomina = [];
      this.cargar = true;
      const payload = {
        fecha_inicio: '2021-06-06',
        fecha_final: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString(),
        id_usuario: this.getdatosUsuario.iderp,
      };
      return this.$http
        .post("consultar.mis.requisiciones", payload)
        .then((response) => {
          console.log( response.data );

          this.liberar = response.data.filter( el => el.estatus == 2 ).length > 5 ? false : true

          this.cargar = false;

        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    editItem(item) {
      this.editedIndex = this.nomina.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    editItem2(item) {
      this.actualizarItems = Object.assign({}, item);
      this.dialogEditarPartida = true;
    },

    editItem3(item) {
      this.actualizarItemsRequi = Object.assign({}, item);
      this.dialogEditarRequi = true;
    },

    close() {
      this.autorizar = null;
      this.nota_rechazo = "";
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    exportar() {
      this.exportExcel(this.editedItem.detalles, "excel_nomina");
    },

    abrirDialgoCargaComprobantes(value) {
      if (!this.requisicionesSelected.length) {
        return this.validarErrorDirecto("Favor de seleccionar la partida");
      }
      this.tipo_pago = value;
      this.dialogSubirComprobante = true;
    },

    cargarArchivo() {
      if (!this.archivos) {
        return;
      }
      //mandamos a ocvertir la imagen a base64 pero mandamos el docuemnto, el formdata, el nombre
      this.getBase64(this.archivos);
    },

    getBase64(file) {
      var me = this;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        me.vistaPrevia = {
          url: reader.result,
          image_name: file.name,
        };
      };
    },

    onChange(e) {
      var files = e.target.files || e.dataTransfer.files;

      if (!files.length) {
        this.dragging = false;
        return;
      }

      this.createFile(files[0]);
    },

    createFile(file) {
      if (file.size > 10000000) {
        alert("please check file size no over 5 MB.");
        this.dragging = false;
        return;
      }

      this.file = file;
      this.archivos = file;
      this.getBase64(this.archivos);
      this.dragging = false;
    },

    removeFile() {
      this.vistaPrevia = null;
      this.archivos = null;
      this.file = "";
    },

    grabarDatos() {
      var formData = new FormData();

      const ids = this.requisicionesSelected.map((registro) => {
        return registro.id;
      });

      formData.append("file", this.file);
      formData.append("requi", ids);
      formData.append("id_usuario_subio", this.getdatosUsuario.iderp);
      formData.append("tipo_pago", this.tipo_pago);

      this.cargar = true;

      return this.$http
        .post("subir.archivos.requi", formData)
        .then((response) => {
          this.validarSuccess(response.data.message);
          this.file = null;
          this.requisicionesSelected = [];
          this.vistaPrevia = null;
          this.cargar = false;
          this.dialogSubirComprobante = false;
          this.initialize();
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    verImagen(imagen) {
      this.imagen = imagen;
      this.dialogImagen = true;
    },

    eliminarRequisicion(value) {
      this.cargar = true;
      const payload = {
        id_requisicion_compra_estatus: 1,
        id_requisicion: this.editedItem.id,
      };

      return this.$http
        .put("eleminar.partida/" + value.id)
        .then((response) => {
          this.$http
            .post("update.estatus.requisicion", payload)
            .then((response) => {
              this.validarSuccess(response.data.message);
              this.cargar = false;
              this.initialize();
            })
            .catch((error) => {
              this.validarError(error);
            })
            .finally(() => {
              this.cargar = false;
            });
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    preAutorizar(estatus) {
      this.cargar = true;

      const payload = {
        id_usuario_autoriza_rechaza: this.getdatosUsuario.iderp,
        id_requisicion_compra_estatus: 5,
        id_requisicion: this.editedItem.id,
      };

      return this.$http
        .post("requisicion.preautorizar", payload)
        .then((response) => {
          this.validarSuccess(response.data.message);
          this.cargar = false;
          this.initialize();
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    updateEstatus(estatus) {
      this.cargar = true;

      const payload = {
        id_requisicion_compra_estatus: estatus,
        id_requisicion: this.editedItem.id,
      };

      return this.$http
        .post("update.estatus.requisicion", payload)
        .then((response) => {
          this.validarSuccess(response.data.message);
          this.cargar = false;
          this.initialize();
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    tipospago() {
      this.cargar = true;
      const payload = {
        tipo_pago: this.tipopago,
        id_requisicion_compra: this.editedItem.id,
      };

      return this.$http
        .post("egresos.actualizar.tipopago", payload)
        .then((response) => {
          this.validarSuccess(response.data.message);
          this.cargar = false;
          this.dialogTipoPago = false;
          this.initialize();
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    cargarFotos() {
      if (this.files.length < 1) {
        return;
      }

      this.files.forEach((element, index) => {
        // creamos una variable tipo FormData
        let formData = new FormData();
        //se crea el objeto y se le agrega como un apendice el archivo
        formData.append("file", element);
        //mandamos a ocvertir la imagen a base64 pero mandamos el docuemnto, el formdata, el nombre
        this.getBase64(element, formData);
      });
    },

    getBase64(file, formData) {
      var me = this;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        const nombreSplit = file.name.split(".");
        const extensioArchivo = nombreSplit[nombreSplit.length - 1];
        console.log(file);
        me.vistaPrevias.push({
          url: reader.result,
          formData: formData,
          image_name: file.name,
          file,
          extensioArchivo,
        });
      };
    },

    eliminarFoto(value) {
      this.vistaPrevias.forEach((element, index) => {
        if (element.url == value) {
          this.vistaPrevias.splice(index, 1);
        }
      });
    },

    eliminarfile(index, value) {
      this.files = this.files.filter((el) => {
        return el.name != value;
      });
    },

    subirFotosServidor() {
      let formData = new FormData();

      for (const i in this.vistaPrevias) {
        formData.append("file", this.vistaPrevias[i].file);
      }

      return new Promise((resolve, reject) => {
        // obtener la extensión del archivo
        // Hacemos la petición
        this.$http
          .post(`egresos.subir.fotos`, formData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            this.validarError(error);
          })
          .finally(() => {
            this.cargar = false;
          });
      });
    },

    async subirCotizacion() {
      // Guardamos el detalle

      this.cargar = true;

      let fotos = [];
      if (this.vistaPrevias.length) {
        let respuesta = await this.subirFotosServidor();
        fotos = respuesta.data;
      }

      var payload = {
        fotos,
        idrequisicion_compra: this.editedItem.id,
        id_usuario_ultimo_cambio: this.getdatosUsuario.iderp,
      };

      return this.$http
        .post("subir.post.cotizaciones", payload)
        .then((response) => {
          this.validarSuccess(response.data.message);
          this.cargar = false;
          // Agregamos primero los datos
          this.dialog = false;
          this.files = [];
          this.vistaPrevias = [];
          this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.editedIndex = -1;
          });
          this.verTabla = true;
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    getPlanteles() {
      this.planteles = [];
      return this.$http
        .get("planteles.activos")
        .then((response) => {
          this.planteles = response.data;
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    getElementosCompras() {
      this.elementos = [];
      return this.$http
        .get("consultar.elemtos.compra")
        .then((response) => {
          this.elementos = response.data;
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    getTipoRequisiciones() {
      this.requisiciones = [];
      return this.$http
        .get("consultar.requisiciones")
        .then((response) => {
          this.requisiciones = response.data;
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    async agregar() {
      // Guardamos el detalle

      let elemento = this.elementos.find(
        (el) =>
          el.id_elemento_mantenimiento ==
          this.editedItems.id_elemento_requisicion
      );
      let plantel = this.planteles.find(
        (el) => el.id_plantel == this.editedItems.id_plantel
      );

      // Validamos que el elemento se haya seleccionado correctamente
      if (!elemento) {
        return this.validarErrorDirecto("Favor de seleccionar un elemento");
      }
      // Validamos que el palntel se haya seleccionado correctamente
      if (!plantel) {
        return this.validarErrorDirecto("Favor de seleccionar un plantel");
      }
      // Validamos que la cantidad se haya seleccionado correctamente
      if (this.editedItems.cantidad == "") {
        return this.validarErrorDirecto("Favor agregar la cantidad");
      }
      // Validamos que el costo se haya seleccionado correctamente
      if (this.editedItems.costo_unitario == "") {
        return this.validarErrorDirecto("Favor de agregar el costo unitario");
      }

      // let detalles = []
      // detalles.push( this.editedItem.detalle )

      this.cargar = true;
      var payload = {
        id_requisicion_compra: this.editedItem.id,
        id_elemento_requisicion: this.editedItems.id_elemento_requisicion,
        id_plantel: this.editedItems.id_plantel,
        concepto: this.editedItems.concepto,
        cantidad: this.editedItems.cantidad,
        costo_unitario: this.editedItems.costo_unitario,
        costo_total: this.editedItems.costo_total,
        id_empleado: 0,
        id_usuario_ultimo_cambio: this.getdatosUsuario.iderp,
      };

      // if (this.detalles.some(detalle => JSON.stringify(detalle) === JSON.stringify(this.editedItems))) {
      // this.validarError('Esta partida ya ha sido agregada anteriormente en esta requisición')
      // return
      // }

      return this.$http
        .post("actualizar.requisicion.partida", payload)
        .then((response) => {
          this.validarSuccess(response.data.message);
          this.cargar = false;
          // Agregamos primero los datos
          this.dialog = false;
          this.dialogPartida = false;
          this.initialize();
          this.$nextTick(() => {
            this.editedItems = Object.assign({}, this.defaultItem);
            this.editedIndex = -1;
          });
          this.verTabla = true;
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    verImagen2(imagen) {
      this.img = imagen;
      this.dialogVer = true;
    },


    editarPartida() {
      this.cargar = true;

      this.costoFinal = this.actualizarItems.costo_unitario * this.actualizarItems.cantidad;

      const payload = {
        id_elemento_requisicion: this.actualizarItems.id_elemento_requisicion,
        id_plantel: this.actualizarItems.id_plantel,
        id_plantel_resultados: this.actualizarItems.id_plantel_resultados,
        concepto: this.actualizarItems.concepto,
        cantidad: this.actualizarItems.cantidad,
        costo_unitario: this.actualizarItems.costo_unitario,
        costo_total: this.costoFinal,
        id: this.actualizarItems.id,
      };

      return this.$http
        .post("update.partida", payload)
        .then((response) => {
          this.validarSuccess(response.data.message);
          this.cargar = false;
          this.initialize();
          this.dialogEditarPartida = false;
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },


    editarRequi() {
      this.cargar = true;

      const payload = {
        id_ciclo: this.actualizarItemsRequi.id_ciclo,
        comentarios: this.actualizarItemsRequi.comentarios,
        id: this.actualizarItemsRequi.id,
      };

      return this.$http
        .post("update.requi", payload)
        .then((response) => {
          this.validarSuccess(response.data.message);
          this.cargar = false;
          this.initialize();
          this.dialogEditarRequi = false;
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

     getCiclos() {
      this.ciclos = [];
      this.overlay = true;
      this.$http
        .get("roles.ciclos.all")
        .then((response) => {
          this.ciclos = response.data;
          this.overlay = false;
        })
        .catch((error) => {
          //Indicamos que hay un error en el back
          if (error.body.message) {
            this.error = error.body.message;
          } else {
            this.error = error.body;
          }
          this.errorBack = true;
          this.overlay = false;
          this.dialogError = true;
        });
    },
  },
};
</script>
<style scoped>
.dropZone {
  width: 100%;
  height: 150px;
  position: relative;
  border: 2px dashed #eee;
}

.dropZone:hover {
  border: 2px solid #d8d8d8;
}

.dropZone:hover .dropZone-title {
  color: #1975a0;
}

.dropZone-info {
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.dropZone-title {
  color: #787878;
}

.dropZone input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dropZone-upload-limit-info {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.dropZone-over {
  background: #d8d8d8;
  opacity: 0.8;
}

.dropZone-uploaded {
  width: 80%;
  height: 200px;
  position: relative;
  border: 2px dashed #eee;
}

.dropZone-uploaded-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #d8d8d8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.removeFile {
  width: 200px;
}
</style>